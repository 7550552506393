import React from "react";
import { Box, Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../../components/head";
import Header from "../../components/Header_normal";
import Footer from "../../components/Footer_nomal";
import Coworking from "../../components/Placearea";
import Navigation from "../../components/navigation";
import Img from "gatsby-image";
import "../../css/base.css";
import "../../css/news.css";
import WrokEnv from "../../components/workEnv";
import TsuriEvent from "../../components/tsuricationButton";
import Concept from "../../components/concept";
import Carousel from "../../components/Carousel";
import WorcarionBase from "../../components/WorcationBase";
import News from "../../components/news";
import Map from "../../components/tsuriMap";
import Fish from "../../components/fish";
import BoxTypo from "../../components/BoxTypo";
import mizutama from "../../../static/images/mizutama.png";

const Home = ({ data }) => {
  return (
    <Box width="100%" bgcolor="#fff">
      <Head />
      <Box
        width="100%"
        m={"auto"}
        pb={30}
        style={{
          backgroundImage: `url(${mizutama})`,
          backgroundRepeat: "no-repeat",
          objectFit: "fit",
        }}
      >
        <Box pt={10} width="90%" m={"auto"} maxWidth="800px">
          <BoxTypo
            Tsukubrdgothic
            fs={["24px", "36px", "48px"]}
            ls={["2px"]}
            lh={["200%"]}
            textAlign="center"
          >
            - NEWS -
          </BoxTypo>
          <BoxTypo
            Tsukubrdgothic
            fs={["24px", "36px", "48px"]}
            ls={["2px"]}
            lh={["150%"]}
            fw={"700"}
            mt={[5]}
            color="#333F69"
          >
            {data.microcmsNews.title}
          </BoxTypo>
          <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]}>
            {data.microcmsNews.date}
          </BoxTypo>
        </Box>
        <Box
          width="90%"
          maxWidth="800px"
          m={"auto"}
          mt={[5, 8, 10]}
          mb={[5, 8, 10]}
          lineHeight="200%"
          textAlign="justifiy"
          className="content"
          fontFamily="Noto Sans JP"
          fontSize="18px"
          dangerouslySetInnerHTML={{
            __html: `${data.microcmsNews.content}`,
          }}
        />
        <Link className="bottom" to="/" width="150px">
          <BoxTypo
            Tsukubrdgothic
            fs={["18px"]}
            ls={["2px"]}
            lh={["200%"]}
            mt={[5]}
            mb={[20]}
            textAlign="center"
          >
            ←TOPへ戻る
          </BoxTypo>
        </Link>
        <News
          isNewsPage={true}
          data={data.allMicrocmsNews.edges.filter(
            (c) => c.node.newsId != data.microcmsNews.newsId,
          )}
        />
      </Box>
    </Box>
  );
};

export default Home;
export const data = graphql`
  query($id: String!) {
    microcmsNews(id: { eq: $id }) {
      title
      date
      content
      newsId
    }
    allMicrocmsNews(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          date
          content
          title
          newsId
        }
      }
    }
  }
`;
